import axios from 'axios';

// 创建 axios 实例
const request = axios.create({
    // axios 中请求配置有 baseURL 选项，表示请求 URL 公共部分
    baseURL: process.env.VUE_APP_API_BASE_URL,
    // 超时
    timeout: 10000,
    // 设置 Content-Type，规定了前后端的交互使用 json
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
});

// 可以在这里添加拦截器，处理请求和响应
request.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    response => {
        // 对响应数据做些什么
        return response;
    },
    error => {
        // 对响应错误做些什么
        return Promise.reject(error);
    }
);

export default request;