<template>
  <div class="change-password">
    <h2>管理后台修改密码</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="username">超级用户名</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div class="form-group">
        <label for="oldPassword">当前密码</label>
        <input type="password" id="oldPassword" v-model="oldPassword" required />
      </div>
      <div class="form-group">
        <label for="newPassword">新密码</label>
        <input type="password" id="newPassword" v-model="newPassword" required />
      </div>
      <div class="form-group">
        <button type="submit" :disabled="isSubmitting">提交</button>
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
    </form>
  </div>
</template>

<script>
import request from '@/api'
export default {
  data() {
    return {
      username: '',
      oldPassword: '',
      newPassword: '',
      isSubmitting: false,
      errorMessage: '',
      successMessage: ''
    };
  },
  methods: {
    async submitForm() {
      this.errorMessage = '';
      this.successMessage = '';

      this.isSubmitting = true;

      try {
        // 使用 axios 发送请求
        const response = await request.post('/cloud/manage/v1/public/admin/modifyAdminPassword', {
          username: this.username,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        });

        if (response.status !== 200) {
          throw new Error('密码修改失败，请稍后再试');
        }

        this.successMessage = '密码修改成功';
        this.oldPassword = '';
        this.newPassword = '';
      } catch (error) {
        this.errorMessage = error.message || '密码修改失败，请稍后再试';
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style scoped>
.change-password {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.change-password:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

button:hover {
  background: linear-gradient(135deg, #0056b3, #007bff);
  transform: translateY(-2px);
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
}

.error-message {
  color: #ff4d4d;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.success-message {
  color: #28a745;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
</style>
